.grid-cpt[data-v-50fc0750] {
  position: relative;
  overflow: hidden;
}
.fade-enter-active[data-v-50fc0750], .fade-leave-active[data-v-50fc0750] {
  transition: opacity .5s .5s;
}
.fade-enter[data-v-50fc0750], .fade-leave-to[data-v-50fc0750] {
  opacity: 0;
}
